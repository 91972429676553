<template>
    <el-card class="box-card">
        <el-button type="primary" class="d-block mr-0 ml-auto" icon="el-icon-check" @click="save"
            >{{ $t('general.save') }}
        </el-button>
        <div class="separator"></div>
        <el-form label-position="top" label-width="100px">
            <el-card shadow="never">
                <div slot="header">
                    <strong>{{ $t('account.profile.financial-header-1') }}</strong>
                </div>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="6">
                        <el-form-item :label="$t('account.profile.financial-company-name')">
                            <el-input :value="companyName" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="4">
                        <el-form-item :label="$t('account.profile.financial-cui')">
                            <el-input :value="companyCui" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="6" :lg="8" :xl="4">
                        <el-form-item :label="$t('account.profile.financial-reg-com')">
                            <el-input :value="regCom" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="6">
                        <el-form-item :label="$t('account.profile.financial-email')">
                            <el-input :value="companyEmail" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="4">
                        <el-form-item :label="$t('account.profile.financial-phone')">
                            <el-input :value="companyPhone" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="4">
                        <el-form-item :label="$t('account.profile.financial-tva')">
                            <el-switch :value="paysTVA == 1" disabled> </el-switch>
                        </el-form-item>

                        <el-checkbox class="card-pay" v-if="acknowledgedTvaFixShow" @change="fixTVAPay">
                            {{ $t('account.profile.financial-tva-acknowledgefix') }}
                        </el-checkbox>
                    </el-col>
                </el-row>
            </el-card>
            <div class="separator"></div>
            <el-card shadow="never">
                <div slot="header">
                    <strong>{{ $t('account.profile.financial-header-3') }}</strong>
                </div>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-form-item :label="$t('account.profile.financial-orders-in-rate')">
                            <div class="sub-title card-pay">{{ $t('account.profile.orders-proc-subtitle') }}</div>
                            <el-checkbox class="card-pay" v-model="allowCreditCard" border>
                                {{ $t('account.profile.orders-proc-checkbox') }}
                            </el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <div class="separator"></div>
            <el-card shadow="never">
                <div slot="header">
                    <strong>{{ $t('account.profile.financial-header-2') }}</strong>
                </div>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                        <el-form-item :label="$t('account.profile.financial-iban')">
                            <el-input :value="bankIBAN" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
                        <el-form-item :label="$t('account.profile.financial-bank')">
                            <el-input :value="bankName" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
    </el-card>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';
export default {
    data: () => ({}),
    computed: {
        ...sync('account/informations@financial', {
            companyName: 'companyName',
            companyCui: 'companyCui',
            companyEmail: 'companyEmail',
            companyPhone: 'companyPhone',
            regCom: 'regCom',
            paysTVA: 'paysTVA',
            acknowledgedTvaFix: 'acknowledgedTvaFix',
            acknowledgedTvaFixShow: 'acknowledgedTvaFix',
            bankIBAN: 'bankIBAN',
            bankName: 'bankName',
            allowCreditCard: 'allowCreditCard',
        }),
    },
    methods: {
        fixTVAPay(data) {
            if (data) {
                this.paysTVA = -2;
            } else {
                this.paysTVA = 0;
            }
        },
        getFinancial: call('account/GetAffiliateFinancial'),
        saveFinancial: call('account/SaveAffiliateFinancial'),
        async save() {
            try {
                await this.saveFinancial();
                this.sbMsg({
                    type: 'success',
                    message: this.$t('general.changes-saved'),
                });
            } catch (err) {
                this.$reportToSentry(err, {
                    extra: {
                        fn: 'saveFinancial',
                        params: {
                            profile: this.$store.get('account/informations@financial'),
                        },
                    },
                });
            }
        },
    },
    async mounted() {
        try {
            await this.getFinancial();
        } catch (err) {
            console.log(err);
            this.$reportToSentry(e, {
                extra: {
                    fn: 'getFinancial',
                },
            });
        }
    },
};
</script>

<style></style>
